import { ContactWithAccessDetails } from 'services/ApiService/ResearchContract/ResearchContractApiClient';
import { ApiRequest, Reducer } from 'store';

export const DEFAULT_SEARCH_CONTACTS_TAKE = 20;
export const DEFAULT_SEARCH_CONTACTS_SKIP = 0;

export const initialState = {
    contactsByContractId: {} as {
        [id: string]: ApiRequest<ContactWithAccessDetails[]> & {
            skip: number | undefined,
            take: number | undefined,
            totalResults: number | undefined,
            indexWatchAccessCount: number | undefined,
        },
    },

} as const;

export type ContactState = typeof initialState;

export const ContactReducer: Reducer<ContactState> = (state = initialState, action) => {
    switch (action.type) {
        case '@CONTACTS/FETCH_CONTACTS_BY_CONTRACT_ID':
            return {
                ...state,
                contactsByContractId: {
                    ...state.contactsByContractId,
                    [action.payload.contractId]: {
                        ...state.contactsByContractId[action.payload.contractId],
                        isFetching: true,
                        didInvalidate: true,
                    },
                },
            };

        case '@CONTACTS/FETCH_CONTACTS_BY_CONTRACT_ID_SUCCESS':
            return {
                ...state,
                contactsByContractId: {
                    ...state.contactsByContractId,
                    [action.payload.contractId]: {
                        ...state.contactsByContractId[action.payload.contractId],
                        skip: action.payload.skip,
                        take: action.payload.take,
                        isFetching: false,
                        didInvalidate: false,
                        totalResults: action.payload.totalResults,
                        data: action.payload.results,
                        indexWatchAccessCount: action.payload.indexWatchAccessCount,
                    },
                },
            };

        case '@CONTACTS/FETCH_CONTACTS_BY_CONTRACT_ID_FAILURE':
            return {
                ...state,
                contactsByContractId: {
                    ...state.contactsByContractId,
                    [action.payload.contractId]: {
                        ...state.contactsByContractId[action.payload.contractId],
                        isFetching: false,
                        didInvalidate: true,
                        data: undefined,
                        totalResults: 0,
                        indexWatchAccessCount: 0,
                    },
                },
            };

        case '@CONTACTS/SET_CHANGE_PAGE_CONTACTS_ACTION':
            if (state.contactsByContractId?.[action.payload.contractId]?.skip === action.payload.skip) {
                return state;
            }
            return {
                ...state,
                contactsByContractId: {
                    ...state.contactsByContractId,
                    [action.payload.contractId]: {
                        ...state.contactsByContractId[action.payload.contractId],
                        skip: action.payload.skip,
                        didInvalidate: true,
                        isFetching: false,
                    },
                },
            };

        case '@CONTACTS/SET_CHANGE_TAKE_CONTACTS_ACTION':
            if (state.contactsByContractId?.[action.payload.contractId]?.take === action.payload.take) {
                return state;
            }
            return {
                ...state,
                contactsByContractId: {
                    ...state.contactsByContractId,
                    [action.payload.contractId]: {
                        ...state.contactsByContractId[action.payload.contractId],
                        take: action.payload.take,
                        didInvalidate: true,
                        isFetching: false,
                    },
                },
            };

        default:
            return state;
    }
};