import { Loading, ServiceLoader } from 'components/common/Loading';
import { Layout } from 'components/Layout';
import React, { lazy, Suspense, useEffect } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { RoutePaths } from './RoutePaths';
import { ProtectedRoute } from './ProtectedRoute';
import { hasRight, Permissions } from 'services/RightsService';
import { useAppContext } from 'components/common/AppProvider';
import { ResearchContractSelectors } from 'store/Normalizr/ResearchContractSelectors';
import { RouteParams } from 'routes/RoutePaths';
import { getAppContext } from 'store/AppContext/AppContextThunk';

const HomeLayout = lazy(() => import('components/Pages/Home/HomeLayout'));
const FormLayout = lazy(() => import('components/Pages/Form/FormLayout'));
const ContactsLayout = lazy(() => import('components/Pages/Contacts/ContactsLayout'));
const NotAuthorized = lazy(() => import('components/Pages/Errors/NotAuthorized'));
const NotFound = lazy(() => import('components/Pages/Errors/NotFound'));

export const AppRoutes: React.FC = () => {
    React.useEffect(() => {
        if (window.location.href.includes('#') && !window.location.href.includes('/#access_token')) {
            window.location.href = window.location.href
                .replace(/\/#\//, '/')
                .replace(/\/#/, '/');
        }
    });

    return <Layout>
        <Suspense fallback={<div className="d-flex justify-content-center"><ServiceLoader /></div>}>
            <Routes>
                <Route path={RoutePaths.Contracts.AllContracts.route}
                    element={<ProtectedRoute><HomeLayout /></ProtectedRoute>}
                />
                <Route path={RoutePaths.Contracts.AllContracts.View.route}
                    element={<ProtectedRoute><FormLayout /></ProtectedRoute>}
                />
                <Route
                    path={RoutePaths.Contracts.AllContracts.Contacts.route}
                    element={<ProtectedRoute ><ContactsLayout /></ProtectedRoute>}
                />
                <Route
                    path={RoutePaths.Contracts.AllContracts.Create.route}
                    element={<ProtectedRoute permission={Permissions.CONTRACT_MANAGER}><FormLayout /></ProtectedRoute>}
                />
                <Route
                    path={RoutePaths.Contracts.AllContracts.Duplicate.route}
                    element={<ProtectedRoute permission={Permissions.CONTRACT_MANAGER}><FormLayout /></ProtectedRoute>}
                />
                <Route
                    path={RoutePaths.Contracts.AllContracts.Edit.route}
                    element={<ProtectedRoute permission={Permissions.CONTRACT_MANAGER}><FormLayout /></ProtectedRoute>}
                />
                <Route
                    path={RoutePaths.Contracts.AllContracts.ContractId.route}
                    element={<ProtectedRoute><RedirectToViewOrEdit /></ProtectedRoute>}
                />
                <Route path={RoutePaths.Errors.NotAuthorized.route} element={<NotAuthorized />} />
                <Route path={RoutePaths.Errors.NotAuthorizedContactUs.route} element={<NotAuthorized isDisplayTryAgain={false} />} />
                <Route path={RoutePaths.Errors.NotFound.route} element={<ProtectedRoute><NotFound /></ProtectedRoute>} />
            </Routes>
        </Suspense>
    </Layout>;
};

const RedirectToViewOrEdit: React.FC = () => {
    const { dispatch, state: { entities, appContext: { loggedUserId, isFetching } } } = useAppContext();
    const { contractId } = useParams<keyof RouteParams['Contracts']['ContractId']>();

    useEffect(() => {
        dispatch(getAppContext());
    }, []);

    const loggedUser = ResearchContractSelectors.getLoggedUser(loggedUserId, entities.researchContract);
    const hasEditRight = hasRight(loggedUser, Permissions.CONTRACT_MANAGER);

    if (isFetching) {
        return <Loading />;
    }

    return contractId ?
        hasEditRight ?
            <Navigate to={RoutePaths.Contracts.AllContracts.Edit.url(contractId)} />
            : <Navigate to={RoutePaths.Contracts.AllContracts.View.url(contractId)} />
        : <NotFound />;
};